import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { removeSpaces } from '../../../../shared/lib/utils';
import api from '../../shared/lib/api';
import { IKontaktperson, IRadgiver } from '../../shared/lib/types';
import Radgivere from './Radgivere';
import KontaktPerson from './KontaktPerson';
import { newInputElement, updateInput } from '../kundedetaljer/kundeFormHelper';
import { IInputElement } from '../../../../shared/lib/types';
import { validateInput } from '../../../../shared/lib/validators';
import TextInput from '../../../form/TextInput';
import TilbakeTil from '../Tilbake';
import Spinner from '../../spinner/Spinner';
import FeilmeldingSide from '../../feilside/FeilmeldingSide';
import toast from 'react-hot-toast';

type TParams = {
    dokumentId?: string;
    kundeId?: string;
    tittel?: string;
};

export default function SendEpost() {
    const { dokumentId, kundeId, tittel } = useParams<TParams>();
    const [kundeNavn, setKundeNavn] = useState<string>();
    const [egetforetak, setEgetForetak] = useState<boolean>(false);
    const [radgivere, setRadgivere] = useState<Array<IRadgiver>>([]);
    const [kontaktPersoner, setKontaktPersoner] = useState<Array<IKontaktperson>>([]);
    const [valgteRadgivere, setValgteRadgivere] = useState<Array<string>>([]);
    const [valgtePersoner, setValgtePersoner] = useState<Array<string>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const [epost, setEpost] = useState<IInputElement>({
        ...newInputElement('', 'Skriv inn e-postadresse(r) (kommaseparert liste)'),
    });
    const onInputChange = (value: any) => {
        setValgteRadgivere(value);
    };
    const onPersonInputChange = (value: any) => {
        setValgtePersoner(value);
    };

    const onSendEpost = () => {
        const valgtePersonerEposter = valgtePersoner
            .map((value) => kontaktPersoner.find((person) => person.id === value).epost)
            .filter((item) => item);
        const epostliste = epost.value.split(',');
        const valgteRadgiverEposter = valgteRadgivere
            .map((value) => radgivere.find((radgiver) => radgiver.brukerId === value).epost)
            .filter((item) => item)
            .join(',');
        const eposter = valgtePersonerEposter.concat(epostliste, valgteRadgiverEposter).filter((item) => item);

        if (eposter.length > 0) {
            if (egetforetak) {
                toast
                    .promise(api.sendEpostEgetForetak(dokumentId, eposter), {
                        loading: 'Sender e-post...',
                        success: <b>E-post er sendt.</b>,
                        error: <b>Kunne ikke sende e-post.</b>,
                    })
                    .then(() => {
                        setValgtePersoner([]);
                        setValgteRadgivere([]);
                        setEpost({
                            ...newInputElement('', 'Skriv inn e-postadresse(r) (kommaseparert liste)'),
                        });
                    });
            } else {
                toast
                    .promise(api.sendEpostKunde(kundeId, dokumentId, eposter), {
                        loading: 'Sender e-post...',
                        success: <b>E-post er sendt.</b>,
                        error: <b>Kunne ikke sende e-post.</b>,
                    })
                    .then(() => {
                        setValgtePersoner([]);
                        setValgteRadgivere([]);
                        setEpost({
                            ...newInputElement('', 'Skriv inn e-postadresse(r) (kommaseparert liste)'),
                        });
                    });
            }
        } else {
            toast.error('Vennligst velg en mottaker.');
        }
    };

    useEffect(() => {
        (() => {
            Promise.all([
                api.getInnloggetBruker().then(async (res) => {
                    if (res.regnskapsforetakId === kundeId) {
                        setKundeNavn(res.regnskapforetakNavn);
                        setEgetForetak(true);
                        const radgivere = await api.getRadgivere();
                        return setRadgivere(radgivere);
                    } else {
                        const kunde = await api.getKunde(kundeId);
                        const kontaktpersoner = kunde.kontaktpersoner;
                        kontaktpersoner.push(kunde.dagligLeder);
                        setKundeNavn(kunde.navn);
                        setKontaktPersoner(kontaktpersoner);
                        if (kunde.harBegrensetKundesynlighet) {
                            const radgivere = kunde.rettigheter;
                            setRadgivere(radgivere);
                        } else {
                            return api.getRadgivere().then((radgivere) => setRadgivere(radgivere));
                        }
                    }
                }),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Send epost';
        })();
    }, [dokumentId, kundeId]);

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <div className="sendepost">
            <div className="page-header">
                <div className="container">
                    <span className={`icon ${egetforetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                    <h2>{kundeNavn && kundeNavn}</h2>
                </div>
            </div>
            <TilbakeTil url={`/kunder/${removeSpaces(kundeId)}/genererte-dokumenter`} tekst="Tilbake til dokumentarkiv" />
            <div className="page">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-6">
                            <div className="section section--white">
                                <h2>Send ({tittel})</h2>
                                <span>Velg kundekontakt som skal motta e-post:</span>
                                {kontaktPersoner.length > 0 && (
                                    <div>
                                        <h4>KUNDEKONTAKTER</h4>
                                        <KontaktPerson
                                            kontaktPersoner={kontaktPersoner}
                                            onInputChange={(value) => onPersonInputChange(value)}
                                            valgtePersoner={valgtePersoner}
                                        />
                                    </div>
                                )}
                                <div>
                                    <h4>RÅDGIVERE</h4>
                                    <Radgivere
                                        radgivere={radgivere}
                                        valgtRadgivere={valgteRadgivere}
                                        onInputChange={(value) => onInputChange(value)}
                                    />
                                </div>
                                <div>
                                    <h4>EKSTRA MOTTAKERE</h4>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TextInput
                                                {...epost}
                                                onInputChange={(_name, value) => {
                                                    setEpost({
                                                        ...updateInput(epost, value),
                                                    });
                                                }}
                                                onInputBlur={() => {
                                                    let input = { ...epost };
                                                    input.validateOnChange = true;
                                                    setEpost({
                                                        ...validateInput(epost),
                                                    });
                                                }}
                                                placeholder="E-postadresse(r)"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <button className="btn btn--primary" onClick={onSendEpost}>
                                            Send e-poster
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
